.App {
  text-align: center;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.side-nav .logo-wrapper {
  border-bottom: none!important;
}

.side-nav.wide {
  width: 12rem!important;
}

.color-link {
  color: #0000EE;
}

.onTop {
  z-index: 999999!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

align-icon {
  vertical-align: middle;
}

.signup-msg {
  margin-bottom: 5%;
}

@media (max-height: 992px) {
  .side-nav .logo-wrapper img {
    padding-top: 10% !important;
  }
}

.link {
  color: #007bff !important;
  cursor: pointer;
}

.small-img-summary {
  width: 40%;
}

.table-setting {
  width: 80%;
}

.welcome-modal {
  position: fixed;
  z-index: 9999999;
}

.propose-submit {
  float: left;
}

.propose-setting {
  width: 60% !important;
}

.post-setting {
  width: 60% !important;
  text-align: left;
}

.signup-pp {
  margin-top: 5%;
}

.ayrshare-color {
  background-color: #3F729B;
}

.firebaseui-idp-password {
  background-color: #3F729B !important;
}

.table-txt {
  text-align: left !important;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  /*font-size: calc(8px + 2vmin);*/
  font-size: 20px;
  color: #424242;
}

.proposal-header {
  min-height: 50vh;
}

.detail-header {
  min-height: 70vh;
}

.opacity {
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

.facebook-color {
  color: #3b5998;
}

.imgOver {
  position: relative;
}

.imgOver img {
  display: block;
}

.imgOver .fa-download {
  position: absolute;
  bottom: 0;
  left: 0;
}

.detail-format {
  width: 100%;
}

.footer-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.opaque {
  opacity: 0.95 !important;
}

.state {
  margin-bottom: 3rem;
}

.last-open {
  font-size: 1rem;
}

.state-header {
  color: #283593;
}

.fa-shopping-cart {
  color: green;
  font-weight: bold;
}

.fa-store-alt {
  color: red;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.picker input { 
  font-size: 1rem!important;
}

.response {
  font-size: 1rem;
}

.home-tooltip {
  font-size: 0.6em;
}

.alert-txt {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.header-style {
  margin-top: 5rem;
  margin-bottom: 1rem;
  width: 55%;
}

.video-header-style {
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 55%;
}

.alert-style {
  margin-top: 4rem;
}

.mobile-logo {
  width: 151px;
  margin-top: 5px;
}

.mobile-ios-logo {
  width: 134px;
  margin-top: 5px;
}

.header-format {
  text-align: left!important;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.header-format-block {
  text-align: left!important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.video-header-format {
  text-align: left!important;
  margin-bottom: 2rem;
}

.navbar .nav-item .nav-link {
  display: inline!important;
}

.img-wrap {
  position: relative;
  display: inline-block;
  /*border: 1px gray solid;*/
  font-size: 0;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #FFF;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: .2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}

.img-wrap:hover .close {
  opacity: 1;
}

.side-nav.wide .collapsible a {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: black;
}

.processed {
  margin-bottom: 2rem;
}

.icon-footer {
  width: 15%;
}

.info {
  font-size: 1.2rem;
}

.logo-login {
  width: 60%;
  margin: 0 auto;
  display: block;
}

.center {
  margin: 0 auto;
  width: 100%;
  display: block;
  text-align: center;
}

.vertical-center {
  margin: 0;
  /* position: absolute;*/
  top: 20%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 320px) and (max-width: 480px) {
  .state {
    margin-bottom: 1.8rem;
  }

  .table-setting {
    width: 95%;
  }

  .header-style {
    width: 95%;
  }

  .propose-setting {
    width: 95% !important;
  }

  .post-setting {
    width: 95% !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}